import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components';

/**
 * Converts a YouTube Shorts URL to a normal YouTube video URL.
 * Otherwise, returned as is.
 *
 * @param {string} url - The YouTube Shorts URL to convert.
 * @returns {string} The converted URL.
 */
export const convertYoutubeShortsToNormalURL = (url) => {    
  if (url.includes("youtube.com") && url.includes("/shorts/")) {
    const videoId = url.split("/shorts/")[1];
    const normalURL = `https://www.youtube.com/watch?v=${videoId}`;
    return normalURL;
  }

  return url;
};


const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 30px;

  @media screen and (max-width: 767px) {
    /* Make player landscape on mobile as well */
    > div {
      height: calc(0.5625 * calc(100vw - 32px)) !important;
    }
  }
`;

const PlayerComponent = styled(ReactPlayer)`
  iframe {
    border-radius: 8px;
  }
`

const AboutMediaEmbed = ({ url, isV4 }) => {
  const convertedURL = convertYoutubeShortsToNormalURL(url)
  const playerHeight = isV4 ? '290px' : '400px'
  return (
    <PlayerWrapper>
      <PlayerComponent width="100%" height={playerHeight} url={convertedURL} controls />
    </PlayerWrapper>
)}

export default AboutMediaEmbed
