import * as React from 'react'

function ArrowDropdownIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10}
      height={6}
      fill="none"
      {...props}
    >
      <path
        fill="#9BA3AB"
        d="M4.06 5.593.763 2.259C.343 1.84.245 1.358.467.815.689.272 1.109 0 1.727 0h6.555c.592 0 1.006.272 1.24.815.235.543.142 1.025-.277 1.444L5.948 5.593a2.319 2.319 0 0 1-.444.296A1.146 1.146 0 0 1 4.985 6c-.172 0-.333-.037-.481-.111a2.319 2.319 0 0 1-.444-.296Z"
      />
    </svg>
  )
}
export default ArrowDropdownIcon
