import * as React from 'react';

function DiscountTagIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      {...props}
    >
      <mask
        id="a"
        width={18}
        height={18}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill="#D9D9D9" d="M18 0H0v18h18z" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#007BFF"
          d="M8.836 15.79c.223.223.49.335.802.335.312 0 .579-.112.802-.335l5.363-5.362c.215-.216.321-.482.319-.8a1.11 1.11 0 0 0-.334-.807l-6.6-6.611a1.14 1.14 0 0 0-.368-.246c-.142-.06-.29-.089-.445-.089H3.012c-.307 0-.57.11-.792.332A1.082 1.082 0 0 0 1.887 3v5.362c0 .16.031.31.093.453.062.141.148.267.256.375l6.6 6.6ZM4.887 5.812a.904.904 0 0 1-.663-.274.904.904 0 0 1-.274-.663c0-.26.091-.48.274-.663a.904.904 0 0 1 .663-.274c.26 0 .481.09.664.274a.904.904 0 0 1 .274.663c0 .26-.092.48-.274.663a.904.904 0 0 1-.664.274Z"
        />
      </g>
    </svg>
  );
}
export default DiscountTagIcon;
