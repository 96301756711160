import React from 'react';
import {
  hideModal
} from '../client/subscribe/actions'

const encodeURIParams = (params) => 
  Object.entries(params)
  .reduce((acc, [key, value]) => acc.concat(
    encodeURIComponent(key) + '=' + encodeURIComponent(value) 
  ), []).join('&')


const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

const handleModal = (modalType) => (event) => {
  event.preventDefault()
  store.dispatch(hideModal(modalType))
}

export default class CoinbaseModal extends React.Component {

  constructor(props){
    super(props)

    const params = {
      origin: `${location.protocol}//${location.hostname}${
        location.port ? ':' + location.port : ''
      }/`,
      buttonId: generateUUID(),
      version: '1',
    }

    const {charge} = props
    const query = encodeURIParams(params)
    const origin = `https://commerce.coinbase.com/embed/charges`
    const src = `${origin}/${encodeURI(charge.code)}?${query}`

    this.buttonId = params.buttonId
    this.state = {src}
  }

  isValidMessage = (msg) => {
    return msg.data.buttonId === this.buttonId;
  }

  handleMessage = (msg) => {
    const { handlePayment } = this.props

    if (!this.isValidMessage(msg)) return

    handlePayment && handlePayment(msg.data)
  }

  componentDidMount(){
    addEventListener('message', this.handleMessage)
  }

  componentWillUnmount() {
    removeEventListener('message', this.handleMessage)
  }

  render() {
    const {src} = this.state
    const {styles, modalStyles, openModal} = this.props

    return (<div 
      className={modalStyles.WelcomeModal.bg} 
    >
      {openModal.modalType === 'coinbase' && <iframe
        style={{
          width: '50%',
          height: '80vh',
          margin: '10vh auto',
          borderRadius: '7px',
        }}
        src={src}
        scrolling="no"
        frameBorder="no"
      />}

      {openModal.modalType === 'coinbase-close' && <div>
        <div className={modalStyles.WelcomeModal.container}>
          <button className={modalStyles.WelcomeModal.closeButton} 
            onClick={handleModal('close')}></button>
          <div className={modalStyles.WelcomeModal.header}>
            Thank You
          </div>

          <div className={modalStyles.WelcomeModal.text}>
            <p>
              If you completed the payment please check your email 
              to join the group.
            </p>
            <p>
              You can check the status of your payment here:&nbsp;
              <a target="_blank" style={{color: 'white'}} href={openModal.modalProps.chargeLink}>Payment Status</a>.
            </p>
            
          </div>
          <div className={modalStyles.WelcomeModal.buttonContainer}>
            <button
              className={modalStyles.WelcomeModal.confirmBtn}
              onClick={handleModal('close')}>GOT IT!</button>
          </div>
        </div>
      </div>}
    </div>)
  }
}