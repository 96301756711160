/* eslint-disable react/function-component-definition */
import React from 'react'
import WelcomeModal from './WelcomeModal'
import ErrorModal from './ErrorModal'
import Spinner from '../clientAdmin/components/Spinner'
import { validateAndPostEmail, clearError, updateFormField } from '../client/subscribe/actions'
import Field from './FieldV2';

const submitForm = () => (event) => {
  event.preventDefault()
  if (typeof clicky !== 'undefined') {
    clicky.goal('freeInviteSignup')
  }

  if (window.lp_event) lp_event('freeInviteSignup')

  const form = event.target.form
  if (form.email) {
    store.dispatch(
      validateAndPostEmail({
        email: form.email.value,
      }),
    )
  }
  return false
}

const Paper = ({
  openModal,
  modalStyles,
  group,
  platform,
  errors,
  styles,
  preview,
  subscribing,
  email,
  buttonColor,
  emailError,
}) => (
  <>
    {openModal && openModal.modalType === 'success' && (
      <WelcomeModal styles={modalStyles} group={group} platform={platform} />
    )}
    {openModal && openModal.modalType === 'error' && (
      <ErrorModal styles={modalStyles} group={group} error={errors} />
    )}
    <div style={preview ? { cursor: 'not-allowed', pointerEvents: 'none' } : {}} className="flex flex-col w-full gap-4">
      <form
        onSubmit={submitForm}
        className="flex flex-col gap-3"
        style={{ width: '100%', textAlign: 'left' }}
      >
        <div className="flex items-center gap-2 mb-2">
          <div className="h-px bg-water-500 flex-1" />
          <div className="text-fiord-500 font-normal w-auto text-sm">
            Join for Free
          </div>
          <div className="h-px bg-water-500 flex-1" />
        </div>
        <Field
          styles={styles.Form}
          error={emailError}
        >
          <input
            type="text"
            name="email"
            placeholder="Enter email address"
            value={email}
            disabled={preview}
            onChange={(e) => {
              store.dispatch(updateFormField('email', (e.target.value)))
              store.dispatch(clearError('email'))
            }}
            onSubmit={submitForm}
          />
        </Field>

        <button
          style={{ backgroundColor: buttonColor ?? 'initial' }}
          type="submit"
          onClick={submitForm(false)}
          disabled={subscribing}
          className={`
            ${styles.TierModal.payButton} bg-primary ${
            subscribing ? 'opacity-30' : ''
          }
        `}
        >
          {subscribing && <Spinner color="#FFFFFF" size={20} />}
          {' '}
          Sign Up now
        </button>
      </form>
    </div>
  </>
)

const EmailForm = ({
  styles,
  errors,
  openModal,
  modalStyles,
  group,
  subscribing,
  buttonColor,
  email,
  platform,
  emailState,
  preview,
  displayName,
  textColor,
}) => {
  const emailErrorStyle = emailState && errors && errors.length && errors[0].field === 'email'
    ? styles.fieldError
    : ''

  const formErrors = errors.reduce((obj, { field, message }) => {
    obj[field] = message
    return obj
  }, {})

  return (
    <Paper
      openModal={openModal}
      modalStyles={modalStyles}
      group={group}
      platform={platform}
      errors={errors}
      styles={styles}
      preview={preview}
      emailError={formErrors.email}
      emailErrorStyle={emailErrorStyle}
      subscribing={subscribing}
      email={email}
      buttonColor={buttonColor}
      displayName={displayName}
      textColor={textColor}
    />
  )
}

export default EmailForm
