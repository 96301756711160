import * as React from 'react'

function CloseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={17}
      fill="none"
      {...props}
    >
      <mask
        id="a"
        width={16}
        height={17}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill="#D9D9D9" d="M0 .293h16v16H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#9BA3AB"
          d="m4.267 12.726-.7-.7L7.3 8.293 3.567 4.56l.7-.7L8 7.593l3.734-3.733.7.7L8.7 8.293l3.734 3.733-.7.7L8 8.993l-3.733 3.733Z"
        />
      </g>
    </svg>
  )
}
export default CloseIcon
