/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import DefaultTOS from './DefaultTOS';

function CustomTOS({
  formErrors,
  textColor,
  customTOS,
  isV3,
  styles,
  onChange,
  name,
}) {
  const handleTOSClick = (event) => {
    onChange && onChange(event.target.checked);
  };

  if (!customTOS) {
    return <DefaultTOS isV4 textColor={textColor} isV3={isV3} styles={styles} />;
  }

  return (
    <div className="flex flex-col gap-2 item-start">
      <label className="!inline-flex items-start">
        <input
          type="checkbox"
          className="mt-[3px] ml-[2px] !border-gray-100 text-indigo-600 focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
          id="tos-checkbox"
          style={{
            borderColor: '#747D85 !important',
          }}
          name={name}
          onClick={handleTOSClick}
        />
        <span className="ml-3 text-left text-gray-100 font-normal">
          By completing signup, I agree to both the terms and conditions of
          {' '}
          <a
            href="https://www.launchpass.com/terms"
            className="text-small text-primary no-underline hover:underline hover:underline-offset-1 visited:text-primary-300"
            target="_blank"
            rel="noreferrer"
          >
            LaunchPass
          </a>
          {' '}
          and the
          {' '}
          <a
            href={customTOS}
            className="text-small text-primary no-underline hover:underline hover:underline-offset-1 visited:text-primary-300"
            target="_blank"
            rel="noreferrer"
          >
            community
          </a>
          .
        </span>
      </label>
      {formErrors.customTOS && !isV3 && (
        <p
          style={{
            marginTop: '10px',
            marginBottom: '0px',
            visibility: 'visible',
            color: textColor,
          }}
        >
          You must accept the Terms &amp; Conditions
        </p>
      )}
    </div>
  );
}

export default CustomTOS;
