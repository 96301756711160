import * as React from 'react'

function CircleStarsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <rect width={24} height={24} fill="#DEF0FF" rx={12} />
      <path
        fill="#007BFF"
        d="M17.396 3.542a.32.32 0 0 1 .6 0l.646 1.747a.32.32 0 0 0 .19.189l1.746.646a.32.32 0 0 1 0 .6l-1.747.646a.32.32 0 0 0-.189.19l-.646 1.746a.32.32 0 0 1-.6 0L16.75 7.56a.32.32 0 0 0-.19-.19l-1.746-.646a.32.32 0 0 1 0-.6l1.747-.646a.32.32 0 0 0 .189-.19l.646-1.746ZM11.293 7.02a.64.64 0 0 1 1.2 0l1.441 3.895a.64.64 0 0 0 .378.378l3.894 1.44a.64.64 0 0 1 0 1.201l-3.894 1.44a.64.64 0 0 0-.378.379l-1.44 3.894a.64.64 0 0 1-1.201 0l-1.441-3.894a.64.64 0 0 0-.378-.378L5.58 13.934a.64.64 0 0 1 0-1.2l3.894-1.441a.64.64 0 0 0 .378-.378l1.44-3.894Z"
      />
    </svg>
  )
}
export default CircleStarsIcon
