/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { updateFormField } from '../client/subscribe/actions';

function DefaultTOS({ textColor, isV3, isV4, styles }) {
  // This way TOS is accepted atomatically when it is enabled
  // when there is no customTOS URL set for the community
  useEffect(() => {
    store.dispatch(updateFormField('tosAccepted', true));
  }, []);

  return (
    <div className={`${isV4 ? 'text-left' : ''}`}>
      <label
        className={`${isV3 && styles.tosLabel} text-sm`}
        style={{ ...(!isV3 && { color: textColor, fontSize: 14 }) }}
        htmlFor="tos"
      >
        <span style={{ display: 'inline-block' }}>
          By completing signup, I agree to the
          {' '}
          <a
            href="https://www.launchpass.com/terms"
            className={`${isV3 && styles.tosLink} text-sm`}
            style={{ ...(!isV3 && { color: textColor, fontSize: 14 }) }}
            target="_blank"
            rel="noreferrer"
          >
            terms & conditions
          </a>
          {' '}
          of LaunchPass.
        </span>
      </label>
    </div>
  );
}

export default DefaultTOS;
