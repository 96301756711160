/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */

import React from 'react'
import classNames from 'classnames'
import {
  toggleCouponField,
  validateCoupon,
  setStripe,
} from '../client/subscribe/actions'
import CheckoutV2 from './CheckoutV2'
import { getFormattedPrices } from '../utils'

export const makePeriodSwitchHandler = (period) => (event) => {
  event.preventDefault()
  store.dispatch({ type: period })

  return false
}

export const getPriceString = (period, formattedPrice) => {
  switch (period) {
    case 'monthly':
      return `${formattedPrice}/month`
    case 'yearly':
      return `${formattedPrice}/year`
    case 'semiannual':
      return `${formattedPrice}/semiannually`
    case 'quarterly':
      return `${formattedPrice}/quarterly`
    case 'weekly':
      return `${formattedPrice}/weekly`
    case 'daily':
      return `${formattedPrice}/day`
    case 'biweekly':
      return `${formattedPrice}/biweekly`
    case 'one-time':
      return `${formattedPrice}/one time`
    default:
      return ''
  }
}

export const isElementVisible = (element) => {
  if (!element) {
    return false
  }

  const rect = element.getBoundingClientRect()

  const windowHeight = window.innerHeight || document.documentElement.clientHeight
  const windowWidth = window.innerWidth || document.documentElement.clientWidth

  // Check if the element is in the viewport.
  const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0
  const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0

  return vertInView && horInView
}

export function StickyButton({
  styles,
  className,
  buttonColor,
  text = 'Join Now',
}) {
  const [isVisible, setIsVisible] = React.useState(false)

  function handleClick() {
    const widget = document.querySelector('.widget')
    widget.scrollIntoView({ behavior: 'smooth' })
  }

  React.useEffect(() => {
    const widget = document.querySelector('.widget')

    function handleScroll() {
      if (isElementVisible(widget)) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // Hide mobile join section when widget displayed & vice-versa
  React.useEffect(() => {
    const widget = document.querySelector('.widget')
    const mobileJoin = document.querySelector('.mobileJoin')

    console.log(isElementVisible(mobileJoin))

    if (isElementVisible(widget)) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [isVisible, setIsVisible])

  return (
    <div
      className={classNames(
        styles.mobileJoinWrapper,
        isVisible ? styles.showOnMobile : '',
        'mobileJoin',
      )}
    >
      <button
        className={className}
        style={{
          background: buttonColor || '#3B5B4F',
        }}
        onClick={handleClick}
      >
        {text}
      </button>
    </div>
  )
}

function StripeFormElementsV4({
  preview,
  styles,
  url,
  price,
  yearlySelected,
  currency = 'usd',
  errors,
  period,
  trialPeriod,
  openModal,
  modalStyles,
  channel,
  image,
  group,
  subscribing,
  couponValidating,
  buttonColor,
  textColor,
  backgroundColor,
  status,
  alternatePayment,
  alternatePaymentAmount,
  joiningPayment,
  joiningPaymentAmount,
  waiveJoiningFeeForYearlyOption,
  convertCode,
  couponsEnabled,
  requireNameEnabled,
  requireAddressEnabled,
  customSetting1Enabled,
  customSetting2Enabled,
  customSetting3Enabled,
  verifyEmailEnabled,
  useOauthEnabled,
  coupon,
  couponFieldVisible,
  couponValid,
  couponId,
  couponAmountOff,
  couponPercentOff,
  couponDurationInMonths,
  couponDuration,
  couponSubmitted,
  couponData,
  customTOS,
  displayName,
  couponCurrency = 'usd',
  email,
  stripeKey,
  stripeKeySCA,
  stripeConnectId,
  stripe,
  offerName,
  platform,
  coinbaseEnabled,
  coinbaseCharge,
  isEmbed,
  isHalfVisible,
}) {

  React.useEffect(() => {
    if (window.stripe) {
      store.dispatch(setStripe(window.stripe))
    } else {
      // set to null for SSR
      store.dispatch(setStripe(null))
    }

    if (coupon) {
      store.dispatch(toggleCouponField(true))
      store.dispatch(validateCoupon(coupon))
    }
  }, [])

  trialPeriod = parseInt(trialPeriod, 10)
  const parsedTrialPeriod = parseInt(trialPeriod, 10)

  const formattedPrices = getFormattedPrices({
    period, price, alternatePayment, alternatePaymentAmount, joiningPayment, joiningPaymentAmount, currency, trialPeriod,
  }, couponData, yearlySelected)
  const inputStyles = status === 'Disabled' ? styles.disabledFormInput : styles.formInput

  if (couponCurrency === null || couponCurrency === undefined) {
    couponCurrency = 'usd'
  }
  if (currency === null || currency === undefined) {
    currency = 'usd'
  }

  const displaySavedPrice = Number(formattedPrices.yearlyDiscountSavingsAmount) > 0

  return (
    <CheckoutV2
      preview={preview}
      isEmbed={isEmbed}
      group={group}
      email={email}
      currency={currency}
      errors={errors}
      channel={channel}
      status={status}
      buttonColor={buttonColor}
      textColor={textColor}
      backgroundColor={backgroundColor}
      styles={modalStyles}
      url={url}
      price={price}
      yearlySelected={yearlySelected}
      period={period}
      openModal={openModal}
      alternatePayment={alternatePayment}
      joiningPayment={joiningPayment}
      joiningPaymentAmount={joiningPaymentAmount}
      waiveJoiningFeeForYearlyOption={waiveJoiningFeeForYearlyOption}
      convertCode={convertCode}
      couponsEnabled={couponsEnabled}
      image={image}
      displayName={displayName}
      verifyEmailEnabled={verifyEmailEnabled}
      useOauthEnabled={useOauthEnabled}
      requireNameEnabled={requireNameEnabled}
      requireAddressEnabled={requireAddressEnabled}
      customSetting1Enabled={customSetting1Enabled}
      customSetting2Enabled={customSetting2Enabled}
      customSetting3Enabled={customSetting3Enabled}
      stripeKey={stripeKey}
      stripeKeySCA={stripeKeySCA}
      stripeConnectId={stripeConnectId}
      customTOS={customTOS}
      offerName={offerName}
      platform={platform}
      couponFieldVisible={couponFieldVisible}
      couponValid={couponValid}
      couponId={couponId}
      couponAmountOff={couponAmountOff}
      couponPercentOff={couponPercentOff}
      couponDurationInMonths={couponDurationInMonths}
      couponDuration={couponDuration}
      couponSubmitted={couponSubmitted}
      couponValidating={couponValidating}
      subscribing={subscribing}
      stripe={stripe}
      inputStyles={inputStyles}
      coinbaseEnabled={coinbaseEnabled}
      coinbaseCharge={coinbaseCharge}
      modalStyles={modalStyles}
      parsedTrialPeriod={parsedTrialPeriod}
      displaySavedPrice={displaySavedPrice}
      formattedPrices={formattedPrices}
      isHalfVisible={isHalfVisible}
    />
  );
}

export default StripeFormElementsV4
