import React from "react";
import classNames from "classnames";

export const Field = ({ styles, htmlFor, name, error, progress, children }) => (
  <div
    className={classNames(
      styles.field,
      error && styles.error,
      progress && styles.progress
    )}
  >
    {progress && (
      <div className={styles.controlProgress}>
        <span className={styles.loader}></span>
      </div>
    )}
    {name && <label htmlFor={htmlFor}>{name}</label>}
    <div className={styles.control}>{children}</div>
    {error && <div className={styles.controlError}>{error}</div>}
  </div>
);
