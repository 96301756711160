import React from 'react'

import { hideModal } from '../client/subscribe/actions'

export const handleModal = (modalType) => (event) => {
  event.preventDefault()
  store.dispatch(hideModal(modalType))
  return false
}

const ErrorModal = ({
  styles,
  error,
}) => (
  <div className={styles.ErrorModal.bg} onClick={handleModal('close')}>
    <div className={styles.ErrorModal.container}>
      <button className={styles.ErrorModal.closeButton} onClick={handleModal('close')}></button>
      <div className={styles.ErrorModal.header}>
        Attention! Read below:
      </div>
      {error[0].message
        && <div className={styles.ErrorModal.text}>
          <p name="errorMessage">
            {error[0].message}
          </p>
        </div>
      }
      <div className={styles.ErrorModal.buttonContainer}>
        <button
          className={styles.ErrorModal.confirmBtn}
          onClick={handleModal('close')}>GOT IT!</button>
      </div>
    </div>
  </div>
)

export default ErrorModal
