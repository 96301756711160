import * as React from 'react'

function AppliedDiscountTagIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={15}
      fill="none"
      {...props}
    >
      <path
        fill="#747D85"
        d="M7.331 14.083c.223.223.49.335.802.335.312 0 .58-.112.802-.335l5.363-5.362c.215-.216.322-.482.32-.8a1.11 1.11 0 0 0-.335-.807L7.683.503a1.14 1.14 0 0 0-.368-.246c-.142-.06-.29-.089-.445-.089H1.507c-.306 0-.57.11-.792.332a1.082 1.082 0 0 0-.333.793v5.362c0 .16.031.31.093.453.062.141.148.267.256.375l6.6 6.6ZM3.382 4.105a.904.904 0 0 1-.663-.274.904.904 0 0 1-.274-.663c0-.26.091-.48.274-.663a.904.904 0 0 1 .663-.275c.26 0 .481.092.664.275a.904.904 0 0 1 .274.663c0 .26-.092.48-.274.663a.904.904 0 0 1-.664.274Z"
      />
      <path
        fill="#fff"
        d="M7.738 9.027v-.293c0-.215.045-.413.136-.593.092-.18.225-.326.4-.436.176-.11.388-.166.637-.166.257 0 .472.055.645.166.173.108.304.252.393.433.09.18.135.38.135.596v.293c0 .215-.045.412-.135.593-.09.18-.223.326-.398.436-.174.11-.387.165-.64.165s-.467-.055-.642-.165a1.076 1.076 0 0 1-.398-.436 1.33 1.33 0 0 1-.133-.593Zm.707-.293v.293c0 .142.035.273.103.392.069.12.19.18.363.18.175 0 .296-.06.36-.177.067-.119.1-.25.1-.395v-.293a.831.831 0 0 0-.094-.395c-.063-.121-.185-.182-.366-.182-.17 0-.29.06-.36.182a.763.763 0 0 0-.106.395ZM4.918 5.852v-.293c0-.217.046-.415.138-.596.092-.18.226-.325.401-.433.175-.11.387-.166.637-.166.254 0 .468.055.642.166.175.108.307.252.395.433.089.18.133.38.133.596v.293c0 .216-.045.415-.135.596-.089.178-.22.322-.396.43a1.193 1.193 0 0 1-.64.163c-.254 0-.469-.054-.644-.163a1.063 1.063 0 0 1-.396-.433 1.308 1.308 0 0 1-.135-.593Zm.712-.293v.293c0 .144.034.276.1.395.07.117.19.176.364.176.173 0 .292-.059.357-.176.067-.12.1-.25.1-.395v-.293a.83.83 0 0 0-.094-.396c-.063-.12-.184-.181-.363-.181-.172 0-.292.06-.36.181a.788.788 0 0 0-.104.396Zm-.403 4.508L9.04 4.519h.678l-3.815 5.548h-.677Z"
      />
    </svg>
  )
}
export default AppliedDiscountTagIcon
