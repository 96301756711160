// CardSection.js
import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

class CardSection extends React.Component {
  render() {
    const { preview, isV3 = false } = this.props;

    const style = {
      base: {
        color: '#333',
        fontSize: '18px',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#333',
        },
        border: "1px solid #c4c4c4",
        borderRadius: "4px",
        padding: "10px",
      },
      invalid: {
        color: '#d20101',
        ':focus': {
          color: '#333',
        },
      },
    };
    const status = this.props.status;

    return (!preview && (status !== 'Disabled')) ? (
      <span style={status === 'Disabled' ? { cursor: 'not-allowed' } : {}}>
        <div
          style={{
            padding: "5px 0",
            backgroundColor: "#fff",
            minHeight: "48px",
            padding: "14px 12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            border: "1px solid #ced4da",
            // v3 designs have 8px border-radius
            borderRadius: isV3 ? 8: 3,
          }}
          className={
            status === 'Disabled'
              ? `${this.props.styles.elementsDisabledContainer}`
              : `${this.props.styles.elementsContainer}`
          }
        >
          <CardElement onBlur={this.props.onBlur} style={style} onChange={this.props.handleCreditCardUpdate} />
        </div>
      </span>
    ) : (
      <div
        className={`${this.props.styles.elementsDisabledContainer} ${status === 'disabled' && 'bg-whitesmoke'}`}
        style={{
          backgroundColor: "#fff",
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          opacity: status === 'Disabled' ? 0.3 : 1,
          ...(isV3 ? {
            opacity: status === 'Disabled' ? 0.5 : 1,
            padding: "3px 0",
            border: '1px solid #ced4da',
            borderRadius: '8px',
            fontSize: '14px',
            lineHeight: '145%',
            color: '#545c63',
            width: '100%',
            height: '50px',
            paddingLeft: '3px',
            paddingRight: '3px'
          } : {})
        }}
      >
        <div>
          <img style={{ width: "100%", maxHeight: 24 }} src="/img/stripe-placeholder-cardnum.png" /> 
        </div>
        <div>
          <img style={{ width: "100%", maxHeight: 24 }} src="/img/stripe-placeholder-mmyy-cvc.png" /> 
        </div>
      </div>
    );
  }
}

export default CardSection;
