import React from 'react'

function ProfileIcons({
  styles, image, mediaEmbed, isV4,
}) {
  const containerClassNames = !isV4 ? styles?.influencerContainer : 'w-[60px] max-h-[60px]'
  return (
    <div className={`overflow-hidden relative block rounded-lg border-white border-1 ${containerClassNames}`}>
      {image && !mediaEmbed
        && (
        <img
          className={`border-0 rounded-none ${styles?.influencer} ${isV4 && 'max-h-[60px] w-[60px object-cover'}`}
          src={image}
          alt=""
        />
        )}
    </div>
  )
}

export default ProfileIcons
