/* eslint-disable react/function-component-definition */
import React from 'react'
import classNames from 'classnames'

import WelcomeModal from './WelcomeModal'
import ErrorModal from './ErrorModal'
import Spinner from '../clientAdmin/components/Spinner'
import { LaunchpassGray } from './Icons'
import { validateAndPostEmail, clearError, updateFormField } from '../client/subscribe/actions'
import { StickyButton } from './StripeFormElementsV2'
import { getJoinButtonText } from '../utils'

const submitForm = () => (event) => {
  event.preventDefault()
  if (typeof clicky !== 'undefined') {
    clicky.goal('freeInviteSignup')
  }

  if (window.lp_event) lp_event('freeInviteSignup')

  const form = event.target.form
  if (form.email) {
    store.dispatch(
      validateAndPostEmail({
        email: form.email.value,
      })
    )
  }
  return false
}

const Paper = ({
  openModal,
  modalStyles,
  group,
  platform,
  errors,
  styles,
  preview,
  emailErrorStyle,
  subscribing,
  email,
  buttonColor,
  textColor,
  emailError,
  displayName,
  trialPeriod,
}) => (
  <>
    {openModal && openModal.modalType === 'success' && (
      <WelcomeModal styles={modalStyles} group={group} platform={platform} />
    )}
    {openModal && openModal.modalType === 'error' && (
      <ErrorModal styles={modalStyles} group={group} error={errors} />
    )}
    <h2 className={styles.getAccess} style={{ color: textColor }}>
      Get access today!
    </h2>
    <div
      className={styles.paper}
      style={preview ? { cursor: 'not-allowed', pointerEvents: 'none' } : {}}
    >
      <header className={styles.header}>Join {displayName}</header>
      <form
        onSubmit={submitForm}
        className={styles.form}
        style={{ width: '100%', textAlign: 'left' }}
      >
        <label htmlFor="email" className={styles.formLabel}>
          Email
        </label>
        <input
          type="text"
          className={classNames(emailErrorStyle, styles.formInput)}
          name="email"
          placeholder="Enter email address"
          value={email}
          disabled={preview}
          onChange={(e) => {
            store.dispatch(updateFormField('email', (e.target.value)))
            store.dispatch(clearError('email'))
          }}
          onSubmit={submitForm}
        />
        {emailError && <div className={styles.controlError}>{emailError}</div>}

        <button
          style={{ background: buttonColor || '#3B5B4F' }}
          type="submit"
          onClick={submitForm(false)}
          disabled={subscribing || preview}
          className={styles.joinButton}
        >
          {subscribing && <Spinner color="#FFFFFF" size={20} />}{' '}
          {getJoinButtonText(trialPeriod)}
        </button>
      </form>

      <hr className={styles.separator} />

      <div className={styles.launchpass}>
        Powered by LaunchPass <LaunchpassGray />
      </div>
    </div>
  </>
)

const EmailForm = ({
  styles,
  errors,
  openModal,
  modalStyles,
  group,
  subscribing,
  buttonColor,
  email,
  platform,
  emailState,
  preview,
  displayName,
  textColor
}) => {

  const emailErrorStyle =
    emailState && errors && errors.length && errors[0].field === 'email'
      ? styles.fieldError
      : ''

  const formErrors = errors.reduce((obj, { field, message }) => {
    obj[field] = message
    return obj
  }, {})

  return (
    <>
      <div className={classNames(styles.paperWrapper, 'widget')}>
        <Paper
          openModal={openModal}
          modalStyles={modalStyles}
          group={group}
          platform={platform}
          errors={errors}
          styles={styles}
          preview={preview}
          emailError={formErrors.email}
          emailErrorStyle={emailErrorStyle}
          subscribing={subscribing}
          email={email}
          buttonColor={buttonColor}
          displayName={displayName}
          textColor={textColor}
        />
      </div>

      {!preview && (
        <StickyButton
          styles={styles}
          buttonColor={buttonColor}
          className={styles.mobileJoinButton}
        />
      )}
    </>
  )
}

export default EmailForm
