import React from 'react';
import NotAcceptingSignupsIcon from './Icons/not-accepting-signups';

function NotAcceptingSignups() {
  return (
    <div className="flex flex-col items-center gap-5">
      <div className>
        <NotAcceptingSignupsIcon />
      </div>
      <div className="text-3xl font-semibold text-black-pearl">
        Signups are temporarily closed!
      </div>
      <div className="text-base font-normal text-fiord-500">
        As we work to accommodate our growing community, signups are temporarily disabled.
      </div>
    </div>
  );
};

export default NotAcceptingSignups;
