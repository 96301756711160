import React from 'react';
import PropTypes from 'prop-types';
import {
  CloseIcon,
  UpsellIcon,
} from './Icons';
import { makePeriodSwitchHandler } from './StripeFormElementsV2';
import Spinner from '../clientAdmin/components/Spinner';

const UpsellModal = ({
  buttonColor,
  onConfirm,
  subscribing,
  setModalOpen,
  displaySavedPrice,
  isInputDisabled,
  formattedPrices
}) => {
  const [selectedButton, setSelectedButton] = React.useState("");

  return (
    <div className={styles.TierModal.upsellBg}>
      <div className={styles.TierModal.upsellContainer}>
        <div className={styles.TierModal.upsellModalBody}>
          <UpsellIcon />
          {!subscribing && (
            <span
              className={styles.TierModal.closeBtn}
              onClick={(_e) => {
                setModalOpen(false);
              }}
            >
            <CloseIcon />
          </span>
          )}
          {displaySavedPrice && (
            <div className={styles.TierModal.upsellSaveValue}>
              Save {formattedPrices.yearlyDiscountSavings}
            </div>
          )}
          <div className={styles.TierModal.upsellText}>
            Would you like to save {formattedPrices.yearlyDiscountSavings} when you subscribe to
            annual billing?
          </div>
          <div className={styles.TierModal.upsellPrice}>
            <p>Price: {formattedPrices.yearlyPriceAfterCoupon}</p>
            <small>{formattedPrices.yearlyRenewText}</small>
          </div>
          <div className={styles.TierModal.upsellButtonsContainer}>
            <button
              className={styles.TierModal.upsellConfirmButton}
              style={{
                background: buttonColor,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
              onClick={(e) => {
                setSelectedButton("yearly");
                makePeriodSwitchHandler("SELECT_YEARLY")(e);
                onConfirm(e);
              }}
              disabled={subscribing}
            >
              {selectedButton === "yearly" && subscribing && (
                <Spinner
                  color="#fff"
                  size={25}
                  style={{ alignSelf: "center" }}
                />
              )}
              Yes, save {formattedPrices.yearlyDiscountSavings}
            </button>
            <button
              className={styles.TierModal.upsellDeclineButton}
              onClick={(e) => {
                setSelectedButton("monthly");
                makePeriodSwitchHandler("SELECT_MONTHLY")(e);
                onConfirm(e);
              }}
              disabled={subscribing}
            >
              {selectedButton === "monthly" && subscribing && (
                <Spinner
                  color="#545c63"
                  size={25}
                  style={{ alignSelf: "center" }}
                />
              )}
              No, let me spend more
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

UpsellModal.propTypes = {
  buttonColor: PropTypes.string,
  onConfirm: PropTypes.func,
  formattedSavedPrice: PropTypes.string,
  formattedPriceYearly: PropTypes.string,
  subscribing: PropTypes.bool,
  setModalOpen: PropTypes.func,
  displaySavedPrice: PropTypes.bool,
};

export default UpsellModal;
