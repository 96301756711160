import React from 'react';
import Spinner from '../clientAdmin/components/Spinner'

function CouponSectionV1({
  styles,
  couponsEnabled,
  couponFieldVisible,
  couponValid,
  couponSubmitted,
  coupon,
  buttonColor,
  couponValidating,
  textColor,
  unsubscribeText,
  onSubmit,
  displayCouponField,
}) {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (onSubmit) {
      return onSubmit(e.target.coupon.value);
    }
  }

  const renderCouponForm = () => (
    <form
      className={styles.couponForm}
      style={{ border: `2px solid ${couponSubmitted ? 'red' : 'white'}` }}
      onSubmit={handleOnSubmit}
    >
      <input
        id="couponField"
        className={styles.couponInputField}
        type="text"
        placeholder="enter coupon..."
        defaultValue={coupon}
        name="coupon"
        onFocus={(e) => { e.target.placeholder = '' }}
        onBlur={(e) => { e.target.placeholder = 'enter coupon...' }}
      />
      <button
        type="submit"
        className={styles.couponButton}
        name="couponButton"
        style={{ borderColor: buttonColor, background: buttonColor }}
      >
        {couponValidating ? <Spinner color="#FFFFFF" size={30} /> : <span>apply</span>}
      </button>
    </form>
  );

  const renderCouponMessage = () => (
    <div className="couponMessage" style={{ marginBottom: '10px', fontSize: '12px', color: textColor }}>
      {couponValid && couponSubmitted && <p name="couponSuccess">Coupon successfully applied!</p>}
      {!couponValid && couponSubmitted && <p name="couponInvalid">Invalid coupon. Please try again.</p>}
    </div>
  );

  if (couponsEnabled && couponFieldVisible) {
    return (
      <div>
        {!couponValid && renderCouponForm()}
        {renderCouponMessage()}
      </div>
    );
  }

  if (couponsEnabled && !couponFieldVisible) {
    return (
      <div className={unsubscribeText} style={{ color: textColor, marginBottom: '10px' }}>
        <span
          tabIndex="-1"
          name="displayCoupon"
          role="button"
          onKeyUp={displayCouponField}
          onClick={displayCouponField}
          style={{ color: textColor || 'white', cursor: 'pointer', textDecorationLine: 'underline' }}
        >
          Have a coupon? Click to apply it.
        </span>
      </div>
    )
  }

  return null;
}

export default CouponSectionV1;
