import React from 'react';

function RecurringPriceLabel({
  period,
  textColor,
  styles,
  formattedPrices,
}) {
  if (period === 'one-time') return null;

  return (
    <div className={styles.priceDetail} style={{ color: textColor }}>
      {formattedPrices.recurringPriceLabel}
    </div>
  );
}

export default RecurringPriceLabel;
