/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
// CheckoutForm.js
import React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import Spinner from '../clientAdmin/components/Spinner'

import CustomTOS from './CustomTOS'

import {
  validateAndPostSubscriptionElements,
  toggleCouponField,
  validateCoupon,
  updateFormField,
  clearError,
  clearNonFieldErrors,
} from '../client/subscribe/actions'
import QuickPay from './QuickPay';
import { formatPrice, getNumber } from '../utils'
import RecurringPriceLabel from './RecurringPriceLabel';
import CouponSectionV1 from './CouponSectionV1';

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { creditCardValid: false }
  }

  handleTOSClick = (checked) => {
    store.dispatch(clearError('customTOS'));
    store.dispatch(clearNonFieldErrors());
    store.dispatch(updateFormField('tosAccepted', checked));
  }

  displayCouponField = () => {
    console.log('displayCouponField');
    store.dispatch(toggleCouponField(true))
  }

  submitCoupon = (couponValue) => {
    store.dispatch(validateCoupon(couponValue))
  }

  handleCardBlur = (ev) => {
    // console.log("handle Blur");
    const cardElement = this.props.elements.getElement('card');
    store.dispatch(updateFormField('card', cardElement))
    // console.log("cardElement", cardElement);
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.handleCardBlur()
    if (typeof clicky !== 'undefined') {
      clicky.goal('paidInviteSignup')
    }
    if (window.lp_event) window.lp_event('initiate_checkout')

    const formattedPrices = this.props.formattedPrices
    store.dispatch(validateAndPostSubscriptionElements({
      provider: 'stripe',
      formattedPrices,
    }, 'v2'))
  }

  handleCreditCardUpdate = (elementType) => {
    if (
      !elementType.empty
      && !elementType.error
      && elementType.complete
    ) {
      this.setState({ creditCardValid: true })
    } else {
      this.setState({ creditCardValid: false })
    }
  }

  render() {
    const { currency } = this.props
    const currencyUC = currency.toUpperCase()

    return (
      <div>
        <form
          onSubmit={this.handleSubmit}
          className={this.props.styles.signupForm}
        >
          <input
            disabled={this.props.status === 'Disabled' ? 'disabled' : false}
            className={[(this.props.formErrors.email || ''), this.props.inputStyles, this.props.styles.email].join(' ')}
            name="email"
            value={this.props.email}
            placeholder="Email"
            onChange={(e) => {
              store.dispatch(clearError('email'))
              store.dispatch(updateFormField('email', e.target.value))
            }}
          />
          <input
            disabled={this.props.status === 'Disabled' ? 'disabled' : false}
            className={[(this.props.formErrors.emailConfirm || ''), this.props.inputStyles, this.props.styles.email, this.props.styles.emailConfirm].join(' ')}
            name="emailConfirm"
            value={this.props.email}
            placeholder="Confirm email"
            onChange={(e) => {
              store.dispatch(clearError('emailConfirm'))
              store.dispatch(updateFormField('emailConfirm', e.target.value))
            }}
            style={{ marginTop: 5 }}
          />

          {this.props.requireNameEnabled
          && (
          <input
            className={[(this.props.formErrors.nameOnCard || ''), this.props.inputStyles, this.props.styles.name].join(' ')}
            name="nameOnCard"
            placeholder="Name on card"
            value={this.props.nameOnCard}
            onChange={(e) => {
              store.dispatch(clearError('nameOnCard'))
              store.dispatch(updateFormField('nameOnCard', e.target.value))
            }}
          />
          )}
          <CardSection
            preview={this.props.preview}
            status={this.props.status}
            onBlur={this.handleCardBlur}
            styles={this.props.styles}
            handleCreditCardUpdate={this.handleCreditCardUpdate}
          />
          <RecurringPriceLabel
            period={this.props.period}
            styles={this.props.styles}
            textColor={this.props.textColor}
            formattedPrices={this.props.formattedPrices}
          />
          <div style={{ width: '80%', margin: '5px auto' }}>
            <CustomTOS
              customTOS={this.props.customTOS}
              formErrors={this.props.formErrors}
              textColor={this.props.textColor}
              onChange={this.handleTOSClick}
            />
          </div>

          {!this.props.subscribing
          && (
          <button
            type="submit"
            disabled={this.props.status === 'Disabled' || !this.state.creditCardValid}
            className={`${this.props.payBtnStyles} test-pay`}
            style={{ background: this.props.buttonColor || '#469CCE', color: this.props.status === 'Disabled' || !this.state.creditCardValid ? 'lightgrey' : '#FFF' }}
          >
            {this.props.btnContent}
          </button>
          )}

          { !this.props.subscribing && (
            <QuickPay {...this.props} completePayment={this.completePayment} />
          ) }

        </form>

        { this.props.subscribing
          && (
          <div>
            <Spinner color="#FFFFFF" size={40} />
          </div>
          )}

        {/* Coupon section */}
        <CouponSectionV1
          styles={this.props.styles}
          couponsEnabled={this.props.couponsEnabled}
          couponFieldVisible={this.props.couponFieldVisible}
          couponValid={this.props.couponValid}
          couponSubmitted={this.props.couponSubmitted}
          coupon={this.props.coupon}
          buttonColor={this.props.buttonColor}
          couponValidating={this.props.couponValidating}
          textColor={this.props.textColor}
          unsubscribeText={this.props.unsubscribeText}
          onSubmit={this.submitCoupon}
          displayCouponField={this.displayCouponField}
        />
      </div>

    );
  }
}

function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm {...props} stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  )
}

export default InjectedCheckoutForm;
