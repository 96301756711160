import React from 'react';
import PropTypes from 'prop-types';
import Switch from './SwitchV2';
import DiscountTagIcon from './Icons/discount-tag';

function AlternatePriceToggle({ onChange, defaultChecked, saveAmount = 0, label, checked }) {
  return (
    <div className={`
      alternate-price-toggle flex justify-between items-center p-3 py-[7px] min-w-[48px] rounded-lg border ${checked ? 'border-primary bg-primary/[.02]' : 'border-water-500'}
    `}>
      <div className="flex gap-2 items-center">
        <div className="text-black-pearl font-semibold text-sm">
          {`Save ${saveAmount}`}
        </div>
        <DiscountTagIcon />
        <div className="text-black-pearl text-xs font-normal">{label}</div>
      </div>
      <Switch onChange={onChange} defaultChecked={defaultChecked} checked={checked} />
    </div>
  );
};

AlternatePriceToggle.propTypes = {
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  saveAmount: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
};

AlternatePriceToggle.defaultValues = {
  saveAmount: 0,
}

export default AlternatePriceToggle;
