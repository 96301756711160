import React from 'react';
import classNames from 'classnames';

function Field({
  styles, htmlFor, name, error, progress, children, className,
}) {
  return (
    <div
      className={`${
        classNames(
          styles.field,
          error && styles.error,
          progress && styles.progress,
          className,
        )
      } m-0`}
    >
      {progress && (
        <div className={styles.controlProgress}>
          <span className={styles.loader} />
        </div>
      )}
      {name && <label htmlFor={htmlFor} className="text-fiord-500 text-sm text-left">{name}</label>}
      <div className={styles.control}>{children}</div>
      {error && <div className={styles.controlError}>{error}</div>}
    </div>
  );
};

export default Field;
