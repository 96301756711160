import * as React from 'react'

function GrayLaunchpassIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="#9BA3AB"
        fillRule="evenodd"
        d="M15.48.893H4.52C2.19.893.303 2.763.303 5.07v10.86c0 2.307 1.887 4.177 4.216 4.177H15.48c2.329 0 4.216-1.87 4.216-4.177V5.07c0-2.307-1.887-4.177-4.216-4.177ZM8.236 13.13v-4.98a4.919 4.919 0 0 1 1.556-3.077 4.919 4.919 0 0 1 1.555 3.076v4.98a2.215 2.215 0 0 1-1.555.496 2.214 2.214 0 0 1-1.556-.495Zm-.481-2.449.084-.167-.002 3.045-1.296.96h-.337v-.607c0-.059 0-.105.005-.15.017-.155.096-.304.447-.968.23-.434.576-1.09 1.099-2.113Zm3.989-.167.084.167c.523 1.023.869 1.679 1.099 2.113.35.664.43.813.447.968.005.045.005.091.005.15v.608h-.338l-1.297-.96v-3.046Zm-1.896 3.278c.096 0 .173.077.173.172v2.062a.172.172 0 0 1-.173.172.172.172 0 0 1-.174-.172v-2.062c0-.095.078-.172.174-.172Zm.814 0a.173.173 0 0 0-.174.172v1.49c0 .094.078.171.173.171a.173.173 0 0 0 .174-.171v-1.49a.173.173 0 0 0-.173-.172Zm-1.621 0c.096 0 .173.077.173.172v1.031a.173.173 0 0 1-.173.172.173.173 0 0 1-.173-.172v-1.031c0-.095.077-.172.173-.172Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export default GrayLaunchpassIcon
