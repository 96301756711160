import React from 'react'

const styles = (size) => `
  @-webkit-keyframes uil-default-anim {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes uil-default-anim {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .uil-default-css > div:nth-of-type(1) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  .uil-default-css {
    position: relative;
    background: none;
    width: ${size}px;
    height: ${size}px;
  }
  .uil-default-css > div:nth-of-type(2) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.4166666666666667s;
    animation-delay: -0.4166666666666667s;
  }
  .uil-default-css {
    position: relative;
    background: none;
    width: ${size}px;
    height: ${size}px;
  }
  .uil-default-css > div:nth-of-type(3) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.33333333333333337s;
    animation-delay: -0.33333333333333337s;
  }
  .uil-default-css {
    position: relative;
    background: none;
    width: ${size}px;
    height: ${size}px;
  }
  .uil-default-css > div:nth-of-type(4) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.25s;
    animation-delay: -0.25s;
  }
  .uil-default-css {
    position: relative;
    background: none;
    width: ${size}px;
    height: ${size}px;
  }
  .uil-default-css > div:nth-of-type(5) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.16666666666666669s;
    animation-delay: -0.16666666666666669s;
  }
  .uil-default-css {
    position: relative;
    background: none;
    width: ${size}px;
    height: ${size}px;
  }
  .uil-default-css > div:nth-of-type(6) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -0.08333333333333331s;
    animation-delay: -0.08333333333333331s;
  }
  .uil-default-css {
    position: relative;
    background: none;
    width: ${size}px;
    height: ${size}px;
  }
  .uil-default-css > div:nth-of-type(7) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  .uil-default-css {
    position: relative;
    background: none;
    width: ${size}px;
    height: ${size}px;
  }
  .uil-default-css > div:nth-of-type(8) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0.08333333333333337s;
    animation-delay: 0.08333333333333337s;
  }
  .uil-default-css {
    position: relative;
    background: none;
    width: ${size}px;
    height: ${size}px;
  }
  .uil-default-css > div:nth-of-type(9) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0.16666666666666663s;
    animation-delay: 0.16666666666666663s;
  }
  .uil-default-css {
    position: relative;
    background: none;
    width: ${size}px;
    height: ${size}px;
  }
  .uil-default-css > div:nth-of-type(10) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
  }
  .uil-default-css {
    position: relative;
    background: none;
    width: ${size}px;
    height: ${size}px;
  }
  .uil-default-css > div:nth-of-type(11) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0.33333333333333337s;
    animation-delay: 0.33333333333333337s;
  }
  .uil-default-css {
    position: relative;
    background: none;
    width: ${size}px;
    height: ${size}px;
  }
  .uil-default-css > div:nth-of-type(12) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: 0.41666666666666663s;
    animation-delay: 0.41666666666666663s;
  }
  .uil-default-css {
    position: relative;
    background: none;
    width: ${size}px;
    height: ${size}px;
  }
`

const Segment = ({ color, size, angle }) => (
  <div style={{
    top: `${size * 0.4}px`,
    left: `${size * 0.465}px`,
    width: `${size * 0.07}px`,
    height: `${size * 0.2}px`,
    background: color,
    WebkitTransform: `rotate(${angle}deg) translate(0,-${size * 0.3}px)`,
    transform: `rotate(${angle}deg) translate(0,-${size * 0.3}px)`,
    borderRadius: `${size * 0.05}px`,
    position: 'absolute',
  }} />
)

const Spinner = ({ color, size, className }) => (
  <div className={className} style={{ display: 'inline-block' }}>
    <style type="text/css" dangerouslySetInnerHTML={{ __html: styles(size) }} />
    <div className="uil-default-css uil-center">
      <Segment color={color} size={size} angle={0} />
      <Segment color={color} size={size} angle={30} />
      <Segment color={color} size={size} angle={60} />
      <Segment color={color} size={size} angle={90} />
      <Segment color={color} size={size} angle={120} />
      <Segment color={color} size={size} angle={150} />
      <Segment color={color} size={size} angle={180} />
      <Segment color={color} size={size} angle={210} />
      <Segment color={color} size={size} angle={240} />
      <Segment color={color} size={size} angle={270} />
      <Segment color={color} size={size} angle={300} />
      <Segment color={color} size={size} angle={330} />
    </div>
  </div>
)

export default Spinner
