import React from 'react';

function PaymentMethods({
  defaultPaymentMethod = 'card',
  onSelect = () => {},
  cryptoEnabled = false,
}) {
  const handleOnSelect = (e) => {
    const { value } = e.target;
    if (onSelect) {
      onSelect(value)
    }
  }

  // If crypto is not enabled
  // card payment is pre-defined as payment method
  if (!cryptoEnabled) {
    return null;
  }

  return (
    <div className="flex items-center rounded-lg bg-alice-blue-500">
      <label
        htmlFor="paymentMethod-card"
        className="relative flex flex-col flex-1 p-3 cursor-pointer peer-checked/card:bg-white"
      >
        <input
          type="radio"
          name="paymentMethod"
          id="paymentMethod-card"
          value="card"
          className="absolute h-0 w-0 appearance-none peer/card opacity-0"
          defaultChecked={defaultPaymentMethod === 'card'}
          onChange={handleOnSelect}
        />
        <div className="flex gap-2 items-center justify-center">
          <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6915 2.04023V13.4402C19.6915 13.9402 19.5165 14.3652 19.1665 14.7152C18.8165 15.0652 18.3915 15.2402 17.8915 15.2402H2.49153C1.99153 15.2402 1.56653 15.0652 1.21653 14.7152C0.866528 14.3652 0.691528 13.9402 0.691528 13.4402V2.04023C0.691528 1.54023 0.866528 1.11523 1.21653 0.765234C1.56653 0.415234 1.99153 0.240234 2.49153 0.240234H17.8915C18.3915 0.240234 18.8165 0.415234 19.1665 0.765234C19.5165 1.11523 19.6915 1.54023 19.6915 2.04023ZM2.19153 4.14023H18.1915V2.04023C18.1915 1.97357 18.1582 1.9069 18.0915 1.84023C18.0249 1.77357 17.9582 1.74023 17.8915 1.74023H2.49153C2.42486 1.74023 2.35819 1.77357 2.29153 1.84023C2.22486 1.9069 2.19153 1.97357 2.19153 2.04023V4.14023ZM2.19153 7.34023V13.4402C2.19153 13.5069 2.22486 13.5736 2.29153 13.6402C2.35819 13.7069 2.42486 13.7402 2.49153 13.7402H17.8915C17.9582 13.7402 18.0249 13.7069 18.0915 13.6402C18.1582 13.5736 18.1915 13.5069 18.1915 13.4402V7.34023H2.19153Z" fill="#1E2328" />
          </svg>
          <div className="text-fiord-500 text-small font-medium peer-checked/card:text-black-pearl">Credit Card</div>
        </div>
        <div
          className="gap-2 hidden peer-checked/card:flex peer-checked/card:bg-white items-center justify-center peer-checked/card:border-black-pearl absolute inset-0 peer-checked/card:border peer-checked/card:rounded-lg"
        >
          <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6915 2.04023V13.4402C19.6915 13.9402 19.5165 14.3652 19.1665 14.7152C18.8165 15.0652 18.3915 15.2402 17.8915 15.2402H2.49153C1.99153 15.2402 1.56653 15.0652 1.21653 14.7152C0.866528 14.3652 0.691528 13.9402 0.691528 13.4402V2.04023C0.691528 1.54023 0.866528 1.11523 1.21653 0.765234C1.56653 0.415234 1.99153 0.240234 2.49153 0.240234H17.8915C18.3915 0.240234 18.8165 0.415234 19.1665 0.765234C19.5165 1.11523 19.6915 1.54023 19.6915 2.04023ZM2.19153 4.14023H18.1915V2.04023C18.1915 1.97357 18.1582 1.9069 18.0915 1.84023C18.0249 1.77357 17.9582 1.74023 17.8915 1.74023H2.49153C2.42486 1.74023 2.35819 1.77357 2.29153 1.84023C2.22486 1.9069 2.19153 1.97357 2.19153 2.04023V4.14023ZM2.19153 7.34023V13.4402C2.19153 13.5069 2.22486 13.5736 2.29153 13.6402C2.35819 13.7069 2.42486 13.7402 2.49153 13.7402H17.8915C17.9582 13.7402 18.0249 13.7069 18.0915 13.6402C18.1582 13.5736 18.1915 13.5069 18.1915 13.4402V7.34023H2.19153Z" fill="#1E2328" />
          </svg>
          <div className="text-fiord-500 text-small font-medium peer-checked/card:text-black-pearl">Credit Card</div>
        </div>
      </label>
      <label
        htmlFor="paymentMethod-crypto"
        className="relative flex flex-col flex-1 p-3 border-0 cursor-pointer peer-checked/crypto:bg-white"
      >
        <input
          type="radio"
          name="paymentMethod"
          id="paymentMethod-crypto"
          value="crypto"
          className="absolute h-0 w-0 appearance-none peer/crypto opacity-0"
          defaultChecked={defaultPaymentMethod === 'crypto'}
          onChange={handleOnSelect}
        />
        <div className="flex gap-2 items-center justify-center">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.931274 11.0047C0.931274 5.43064 5.38136 0.875601 10.9621 0.902462C16.9012 0.930154 21.1403 5.80393 21.0677 11.1341C20.9946 16.4955 16.7724 21.0564 10.953 21.0973C5.36336 21.1369 0.930998 16.6112 0.931274 11.0047Z" fill="#545C63" />
            <path d="M7.8572 4.78651C9.69179 3.79127 11.9861 3.71151 13.894 4.54753C15.9291 5.41706 17.4541 7.30232 17.9542 9.44928C16.8524 9.45232 15.7488 9.48888 14.6484 9.42851C13.9957 7.60084 11.7377 6.63162 9.9305 7.26466C8.33018 7.76145 7.12641 9.39334 7.25213 11.0861C7.23164 13.075 9.02442 14.8456 11.0052 14.8406C12.5897 14.9314 14.1134 13.8916 14.7121 12.4428C15.7934 12.4347 16.872 12.4317 17.9542 12.4444C17.5593 14.1588 16.5383 15.7525 15.056 16.7206C13.3945 17.8975 11.1996 18.2143 9.249 17.6857C7.40361 17.1714 5.77782 15.8799 4.91632 14.1619C4.01661 12.4422 3.88286 10.3421 4.58042 8.52824C5.16001 6.93291 6.35741 5.57795 7.8572 4.78651Z" fill="white" />
          </svg>
          <div className="text-fiord-500 text-small font-medium peer-checked/crypto:text-black-pearl">Crypto</div>
        </div>
        <div
          className="hidden peer-checked/crypto:flex peer-checked/crypto:bg-white items-center justify-center peer-checked/crypto:border-black-pearl absolute inset-0 peer-checked/crypto:border peer-checked/crypto:rounded-lg"
        >
          <div className="flex gap-2 items-center justify-center">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.931152 11.0047C0.931152 5.4306 5.38124 0.875555 10.962 0.902416C16.9011 0.930108 21.1401 5.80388 21.0676 11.134C20.9945 16.4954 16.7723 21.0563 10.9529 21.0973C5.36324 21.1369 0.930875 16.6112 0.931152 11.0047Z" fill="#007BFF"/>
              <path d="M7.85696 4.78648C9.69155 3.79124 11.9858 3.71148 13.8938 4.5475C15.9289 5.41703 17.4539 7.30229 17.954 9.44925C16.8521 9.45229 15.7486 9.48885 14.6481 9.42848C13.9954 7.60081 11.7374 6.63159 9.93025 7.26463C8.32994 7.76142 7.12617 9.39331 7.25189 11.0861C7.2314 13.0749 9.02417 14.8456 11.005 14.8406C12.5895 14.9314 14.1131 13.8916 14.7118 12.4427C15.7932 12.4347 16.8718 12.4317 17.954 12.4444C17.5591 14.1588 16.5381 15.7525 15.0557 16.7206C13.3942 17.8975 11.1994 18.2143 9.24875 17.6856C7.40336 17.1714 5.77757 15.8799 4.91608 14.1619C4.01637 12.4422 3.88262 10.342 4.58018 8.52821C5.15977 6.93288 6.35717 5.57792 7.85696 4.78648Z" fill="white"/>
            </svg>
            <div className="text-fiord-500 text-small font-medium peer-checked/crypto:text-black-pearl">Crypto</div>
          </div>
        </div>
      </label>
    </div>
  );
}

export default PaymentMethods;
