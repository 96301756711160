import React, { useEffect, useState } from 'react'
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js'

import {
  displayErrors,
  updateFormField,
  validateAndPostSubscriptionElements,
} from '../client/subscribe/actions'
import { getNumber } from '../utils';

function QuickPay({
  currency,
  stripe,
  channel,
  period,
  offerName,
  isEmbed,
  formattedPrices,
  coinbaseEnabled,
}) {
  const handlePaymentClick = async (event) => {
    const errors = []
    const { form, page } = store.getState()

    if (page.customTOS && (form.tosAccepted === undefined || form.tosAccepted === false)) {
      errors.push({ field: 'customTOS', message: 'Please accept our terms of service.' })
    }

    if (errors.length > 0) {
      event.preventDefault()
      console.log('found errors', errors)
      store.dispatch(displayErrors(errors))
      return false
    }

    if (pr) {
      await pr.update({
        total: {
          label,
          amount: (
            !page.trialPeriod
            || !parseInt(page.trialPeriod))
            ? Number((getNumber(formattedPrices.currentPriceAfterCoupon) * 100).toFixed(0))
            : 0,
        },
      })
    }

    store.dispatch(updateFormField('paymentRequest', pr))
    return true
  }
  const onPaymentMethodReceived = async (ev) => {
    // console.log('payment method recieved', ev)
    store.dispatch(updateFormField('paymentMethod', ev))
    store.dispatch(updateFormField('paymentMethodId', ev.paymentMethod.id))
    store.dispatch(updateFormField('email', ev.payerEmail))
    store.dispatch(updateFormField('emailConfirm', ev.payerEmail.id))

    store.dispatch(
      validateAndPostSubscriptionElements({
        provider: 'stripe',
      }, 'V4', isEmbed),
    )
  }

  const [pr, setPr] = useState(null)
  const label = `${offerName || channel} ${period || 'monthly'}`
  useEffect(() => {
    const { page } = store.getState()

    if (stripe) {
      const _pr = stripe.paymentRequest({
        country: page.country || 'US',
        currency,
        total: {
          label,
          amount: (!page.trialPeriod || !parseInt(page.trialPeriod)) ? Number((getNumber(formattedPrices.currentPriceAfterCoupon) * 100).toFixed(0)) : 0,
        },
        requestPayerEmail: true,
      })

      // Check the availability of the Payment Request API.
      _pr.canMakePayment().then((result) => {
        if (result) {
          // console.log('payment request created')
          setPr(_pr)
          _pr.on('paymentmethod', onPaymentMethodReceived)
        } else {
          console.log('cant use PaymentRequest', result)
        }
      })
    }
  }, [stripe])

  if (pr) {
    return (
      <>
        <div>
          <div style={{
            margin: 'auto', marginTop: '5px', width: '100%',
          }}
          >
            {pr && (
              <PaymentRequestButtonElement
                onClick={handlePaymentClick}
                options={{ paymentRequest: pr }}
              />
            )}
          </div>
        </div>
        {!coinbaseEnabled && (
          <div className="flex items-center gap-2 mb-2">
            <div className="h-px bg-water-500 flex-1" />
            <div className="text-fiord-500 font-normal w-auto text-sm">
              Or enter payment details
            </div>
            <div className="h-px bg-water-500 flex-1" />
          </div>
        )}
      </>
    )
  }

  if (!coinbaseEnabled) {
    return (
      <div className="flex items-center gap-2 mb-2">
        <div className="h-px bg-water-500 flex-1" />
        <div className="text-fiord-500 font-normal w-auto text-sm">
          Enter payment details
        </div>
        <div className="h-px bg-water-500 flex-1" />
      </div>
    )
  }

  return null;
}

export default QuickPay
